<template>
  <div class="mt-5">
    <client-jobs-display />
  </div>
</template>

<script>
import ClientJobsDisplay from "@core/components/shared/jobs/ClientJobsDisplay.vue";

export default {
  components: {
    ClientJobsDisplay
  },
}
</script>

