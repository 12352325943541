<template>
  <section class="">
    <b-row>
      <b-col cols="12" md="2" class="mb-1">
        <v-select
          class="filter-select"
          placeholder="Region"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :value="region"
          :options="regionOptions"
          :reduce="val => val.value"
          :disabled="loading"
          @input="(val) => region = val"
        />
      </b-col>

      <b-col cols="12" md="2" class="mb-1">
        <v-select
          class="filter-select"
          placeholder="City"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :value="city"
          :options="cityOptions"
          :reduce="val => val.value"
          :disabled="loading"
          @input="(val) => city = val"
        />
      </b-col>

      <b-col cols="12" md="2" class="mb-1">
        <v-select
          class="filter-select text-dark"
          placeholder="Job Type"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :value="job_type"
          :options="jobTypeOptions"
          :reduce="val => val.value"
          :disabled="loading"
          @input="(val) => job_type = val"
        />
      </b-col>

      <b-col cols="12" md="2" class="mb-1">
        <v-select
          class="filter-select text-dark"
          placeholder="Workplace"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :value="work_place_type"
          :options="workPlaceOptions"
          :reduce="val => val.value"
          :disabled="loading"
          @input="(val) => work_place_type = val"
        />
      </b-col>

      <b-col cols="12" md="2" class="mb-1">
        <v-select
          class="filter-select text-dark"
          placeholder="Job Interests"
          multiple
          :close-on-select="false"
          :clearable="false"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :value="job_interests"
          :options="jobCategoryOptions"
          :reduce="val => val.value"
          :disabled="loading"
          @input="(val) => job_interests = val"
        />
      </b-col>

      <b-col cols="12" md="2" class="mb-1">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="primary"
          @click="fetchAllData()"
        >
          <span class="align-middle text-lg">Find Job</span>
        </b-button>
      </b-col>
    </b-row>

    <b-row v-if="jobs.length > 0" class="mt-2">
      <b-col v-for="(job, i) in jobs" :key="i" md="6">
        <b-card no-body class="overflow-hidden rounded-lg">
          <b-row no-gutters>
            <b-col md="3" class="card-body ml-2">
              <div class="d-flex align-items-center">
                <b-avatar
                  size="lg"
                  :src="getValueFromSource(job, 'company.logo')"
                  class="mr-1"
                />

                <div>
                  <p class="m-0">{{ getValueFromSource(job, 'company.company_name') }}</p>
                  <p class="m-0">{{ getValueFromSource(job, 'company.company_address') }}</p>
                </div>
              </div>
            </b-col>

            <b-col md="7">
              <div class="card-body">
                <div>
                  <h5 class="job-title-">
                    {{ getValueFromSource(job, 'job.title') }}
                  </h5>
                </div>
                <div>
                  <p class="job-content-" style="line-height: 14px">
                    {{ extractTextContentFromHTML(getValueFromSource(job, 'job.content')) }}
                  </p>
                  <div>
                    <router-link :to="{ name: 'client-single-job', params: { job_id: getValueFromSource(job, '_id')} }">
                      <h6 class="text-secondary">
                        Apply Here ->
                      </h6>
                    </router-link>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <div v-else-if="!loading">
      <h3 class="my-5">
        No jobs available
      </h3>
    </div>

    <div class="d-flex justify-content-start align-items-center">
      <div class="my-2">
       <b-pagination v-model="currentPage" :total-rows="totalRecords" :per-page="limit" first-number last-number prev-class="prev-item" next-class="next-item">
         <template #prev-text>
           <feather-icon icon="ChevronLeftIcon" size="18" />
         </template>
         <template #next-text>
           <feather-icon icon="ChevronRightIcon" size="18" />
         </template>
       </b-pagination>
      </div>
    </div>
  </section>
</template>

<script>
import {
  BCol,
  BRow,
  BCard,
  BBadge,
  BCardImg,
  BOverlay,
  BPagination,
  BButton,
  BAvatar
} from "bootstrap-vue";
import { get } from "lodash";
import { kFormatter } from "@core/utils/filter";
import Search from '@/assets/svg/Search.svg';

import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

const watchHandler = {
  handler() {
    this.fetchAllData()
  },
  immediate: false
}

const watchHandlerRequiresPageReset = {
  handler(){
    this.currentPage = 1;
    this.fetchAllData()
  },
  immediate: false
}


export default {
  name: "ListJobs",
  components: {
    BRow,
    BCol,
    BCard,
    BBadge,
    Search,
    vSelect,
    BCardImg,
    BOverlay,
    BButton,
    BAvatar,
    BPagination
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: true,
      jobs: [],
      searchFilter: "",
      work_place_type: "",
      job_type: "",
      job_interests: [],
      country: 2300660,
      region: "",
      city: "",

      limit: 10,

      jobTypeOptions: [
        "Full-time",
        "Part-time",
        "Contract",
        "Self-employed",
        "Temporary",
        "Freelance",
        "Internship",
        "Remote",
        "Volunteer"
      ].map(type => ({ label: type, value: type })),

      workPlaceOptions: [
        "On-site",
        "Hybrid",
        "Remote",
      ].map(type => ({ label: type, value: type })),

      countryOptions: [],
      regionOptions: [],
      cityOptions: [],

      debounceFetchAllData: null
    }
  },
  computed: {
    settings(){
      return this.$store.getters[`auth/settings`];
    },
    perPage: {
      get() {
        return this.$store.getters[`navigation/recordsPerPage`];
      },
      set(value){
        this.$store.commit(`navigation/UPDATE_RECORDS_PER_PAGE`, value);
      }
    },
    currentPage: {
      get() {
        return this.$store.getters[`navigation/currentPage`];
      },
      set(value){
        this.$store.commit(`navigation/UPDATE_CURRENT_PAGE_NUMBER`, value)
      }
    },
    totalRecords: {
      get(){
        return this.$store.getters[`navigation/totalRecords`];
      },
      set(value){
        this.$store.commit(`navigation/UPDATE_TOTAL_RECORDS`, value)
      }
    },
    userCity() {
      return this.getValueFromSource(this.currentUser, 'city')
    },
    userRegion() {
      return this.getValueFromSource(this.currentUser, 'region') 
    }
  },
  watch: {
    perPage: watchHandlerRequiresPageReset,
    currentPage: watchHandler,
    region: {
      handler(changed){
        if (changed){
          this.fetchGeoData(changed, this.onCitiesFetch);
        }
        if (changed === null) {
          this.city = ''
        }
        this.fetchAllData()
      },
      immediate: false
    },
    city: {
      handler() {
        this.fetchAllData()
      }
    }
  },
  created() {
    this.fetchGeoData(this.country, this.onRegionsFetch);

    const {
      page = 1,
      region,
      city,
      job_type = "",
      work_place_type = "",
      job_interests = "",
      limit = 10
    } = this.$route.query;

    const queryKeys = Object.keys(this.$route.query)

    const hasRegionQuery = queryKeys.includes("region")
    const hasCityQuery = queryKeys.includes("city")

    if (region) {
      this.region = +region
    } else if (!hasRegionQuery && this.userRegion) {
      this.region = this.userRegion
    }

    if (city) {
      this.city = +city
    } else if (!hasCityQuery && this.userCity) {
      this.city = this.userCity
    }

    this.job_type = job_type;
    this.work_place_type = work_place_type;

    if (job_interests){
      this.job_interests = job_interests.split(",")
    }

    this.limit = +limit;
    this.currentPage = +page;
    this.fetchAllData();
  },
  methods: {
    async fetchGeoData(geo_id, cb) {
      try {
        this.loading = true;

        const request = await this.useJwt().sharedService.fetchGeoData({ geo_id });
        const { data } = request.data;
        cb(data)
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async fetchAllData() {
      try {
        this.loading = true;

        const query = {
          region: this.region,
          city: this.city,
          job_type: this.job_type,
          work_place_type: this.work_place_type,
          job_interests: this.job_interests.join(","),

          limit: this.limit,
          page: this.currentPage
        }

        this.$router.push({ query }).catch(() => {});

        const request = await this.useJwt().sharedService.fetchJobs(query);
        const { data, pagination } = request.data;
        this.jobs = data;

        this.totalRecords = pagination.totalRecords
        this.limit = pagination.limit;
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    onRegionsFetch(data) {
      this.cityOptions = [];
      this.townOptions = [];

      this.regionOptions = data.map(geo_data => ({
        label: geo_data.name,
        value: geo_data.geonameId
      }));

      if (!this.region) {
        this.region = ''
      }
    },
    onCitiesFetch(data) {
      this.townOptions = [];

      this.cityOptions = data.map(geo_data => ({
        label: geo_data.name,
        value: geo_data.geonameId
      }));

      if (this.city) {
        const city_option = data.find(item => item.geonameId)
        if (!city_option) {
          this.city = ''
        }
      } else {
        this.city = '';
      }
    },
    kFormatter,
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.job-title- {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.job-content- {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
